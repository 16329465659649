import { default as _91form_93xX4fnqfcMTMeta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedk3sDK7VXJTMeta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishKUvQih1p4oMeta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as interactioneMPn6BVjjwMeta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/interaction.vue?macro=true";
import { default as startwX1iT15fuIMeta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93OSCWHBSEppMeta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexvjT3qjWAQlMeta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginyNG3IsApX3Meta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectlzSRFQqV9DMeta } from "/codebuild/output/src1099556444/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_93OSCWHBSEppMeta?.name ?? "research",
    path: _91_91research_93_93OSCWHBSEppMeta?.path ?? "/:research?",
    meta: _91_91research_93_93OSCWHBSEppMeta || {},
    alias: _91_91research_93_93OSCWHBSEppMeta?.alias || [],
    redirect: _91_91research_93_93OSCWHBSEppMeta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93xX4fnqfcMTMeta?.name ?? "research-form",
    path: _91form_93xX4fnqfcMTMeta?.path ?? ":form()",
    meta: _91form_93xX4fnqfcMTMeta || {},
    alias: _91form_93xX4fnqfcMTMeta?.alias || [],
    redirect: _91form_93xX4fnqfcMTMeta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: deniedk3sDK7VXJTMeta?.name ?? "research-denied",
    path: deniedk3sDK7VXJTMeta?.path ?? "denied",
    meta: deniedk3sDK7VXJTMeta || {},
    alias: deniedk3sDK7VXJTMeta?.alias || [],
    redirect: deniedk3sDK7VXJTMeta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finishKUvQih1p4oMeta?.name ?? "research-finish",
    path: finishKUvQih1p4oMeta?.path ?? "finish",
    meta: finishKUvQih1p4oMeta || {},
    alias: finishKUvQih1p4oMeta?.alias || [],
    redirect: finishKUvQih1p4oMeta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: interactioneMPn6BVjjwMeta?.name ?? "research-interaction",
    path: interactioneMPn6BVjjwMeta?.path ?? "interaction",
    meta: interactioneMPn6BVjjwMeta || {},
    alias: interactioneMPn6BVjjwMeta?.alias || [],
    redirect: interactioneMPn6BVjjwMeta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/interaction.vue").then(m => m.default || m)
  },
  {
    name: startwX1iT15fuIMeta?.name ?? "research-start",
    path: startwX1iT15fuIMeta?.path ?? "start",
    meta: startwX1iT15fuIMeta || {},
    alias: startwX1iT15fuIMeta?.alias || [],
    redirect: startwX1iT15fuIMeta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexvjT3qjWAQlMeta?.name ?? "index",
    path: indexvjT3qjWAQlMeta?.path ?? "/",
    meta: indexvjT3qjWAQlMeta || {},
    alias: indexvjT3qjWAQlMeta?.alias || [],
    redirect: indexvjT3qjWAQlMeta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginyNG3IsApX3Meta?.name ?? "login",
    path: loginyNG3IsApX3Meta?.path ?? "/login",
    meta: loginyNG3IsApX3Meta || {},
    alias: loginyNG3IsApX3Meta?.alias || [],
    redirect: loginyNG3IsApX3Meta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectlzSRFQqV9DMeta?.name ?? "redirect",
    path: redirectlzSRFQqV9DMeta?.path ?? "/redirect",
    meta: redirectlzSRFQqV9DMeta || {},
    alias: redirectlzSRFQqV9DMeta?.alias || [],
    redirect: redirectlzSRFQqV9DMeta?.redirect,
    component: () => import("/codebuild/output/src1099556444/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]