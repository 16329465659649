
import * as awsAmplifyRuntime$epZNvd3J3I from '/codebuild/output/src1099556444/src/form.winx.ai/node_modules/@nuxt/image/dist/runtime/providers/awsAmplify'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "awsAmplify",
  "domains": [
    "\"d3egguu6cudcty.cloudfront.net\""
  ],
  "alias": {
    "/winx": "https://d3egguu6cudcty.cloudfront.net/"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['awsAmplify']: { provider: awsAmplifyRuntime$epZNvd3J3I, defaults: {} }
}
        